<template>
  <div class="menu-edit-page w-100">
    <basic-subheader title="Tạo mới khảo sát">
      <template v-slot:actions>
        <b-button class="btn ml-3" type="button" @click="returnPage">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Arrows/arrow-left.svg" />
          </span>
          Huỷ
        </b-button>
        <b-button
          v-if="showBtnActiveAndBeta"
          class="btn btn-warning ml-3"
          type="button"
          @click="showPopupChangeStatus(3)"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
          </span>
          Beta
        </b-button>
        <b-button
          v-if="showBtnActive || showBtnActiveAndBeta"
          class="btn btn-primary ml-3"
          type="button"
          @click="showPopupChangeStatus(1)"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
          </span>
          Active
        </b-button>
        <b-button
          v-if="showBtnInactive"
          class="btn btn-danger ml-3"
          type="button"
          @click="showPopupChangeStatus(0)"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
          </span>
          Inactive
        </b-button>
        <b-button
          class="btn btn-success ml-3"
          type="button"
          :disabled="loading"
          @click="handleValidate"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu
        </b-button>
      </template>
    </basic-subheader>
    <b-container fluid class="menu-edit-page__body mb-6 mt-6">
      <b-overlay :show="loading">
        <SurveyForm
          ref="form"
          :data="surveyDetail"
          is-update
          :id="id"
          @submit="submit"
        />
      </b-overlay>
    </b-container>
  </div>
</template>
<script>
import {
  EDIT_SURVEY,
  RESET_STATE_SURVEY,
  GET_SURVEY_DETAIL,
  CHANGE_STATUS_SURVEY,
} from '@/core/services/store/survey.module';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapMutations, mapState } =
  createNamespacedHelpers('survey');
export default {
  name: 'SurveyEdit',
  components: {
    SurveyForm: () => import('./components/surveyForm.vue'),
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(['surveyDetail']),
    showBtnActive() {
      return this.surveyDetail?.status === 3;
    },
    showBtnActiveAndBeta() {
      return [0, 2].includes(this.surveyDetail?.status);
    },
    showBtnInactive() {
      return this.surveyDetail?.status === 1;
    },
  },
  beforeDestroy() {
    this.RESET_STATE_SURVEY();
  },
  created() {
    this.getSurveyDetail();
  },
  methods: {
    ...mapActions({ EDIT_SURVEY, GET_SURVEY_DETAIL, CHANGE_STATUS_SURVEY }),
    ...mapMutations({ RESET_STATE_SURVEY }),
    async getSurveyDetail() {
      this.loading = true;
      await this.GET_SURVEY_DETAIL(this.id);
      this.loading = false;
    },
    returnPage() {
      this.$router.go(-1);
    },
    handleValidate() {
      this.$refs.form.handleValidate();
    },
    async submit(params) {
      this.loading = true;
      let fileId = null;
      if (params.file) {
        // upload file
        const { error, data } = await this.$store.dispatch(
          'context/UPLOAD_IMAGE',
          params.file,
        );
        if (error) {
          this.loading = false;
          return this.$toastr.s({
            title: 'Lỗi !',
            msg: error,
            preventDuplicates: true,
          });
        }

        fileId = data.id;
      }

      if (fileId) {
        params['coverId'] = fileId;
        delete params.file;
      }

      // edit survey
      const { error } = await this.EDIT_SURVEY(params);
      if (!error) {
        this.returnPage();
      } else {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
          preventDuplicates: true,
        });
      }
      this.loading = false;
    },
    getNameStatus(state) {
      switch (state) {
        case 1:
          return 'Active';
        case 0:
          return 'Inactive';

        default:
          return 'Beta';
      }
    },
    showPopupChangeStatus(state) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn chuyển trạng thái thành ${this.getNameStatus(
          state,
        )} không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.changeStatus(state);
          }
        }.bind(this),
      );
    },
    async changeStatus(state) {
      this.loading = true;
      const params = {
        id: this.id,
        state,
      };
      const { error } = await this.CHANGE_STATUS_SURVEY(params);
      if (!error) {
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật thành công',
        });
        this.getSurveyDetail();
      } else {
        this.$toastr.s({
          title: 'Lỗi !',
          msg: error,
        });
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-danger {
  background-color: #ff5756 !important;
  border-color: #ff5756 !important;
  color: #fff !important;
}
</style>
